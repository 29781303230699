import React from 'react'
import { string, bool } from 'prop-types'

import Logo from '@/component/Primitive/Logo'
import SmartLink from '@/component/Primitive/SmartLink'
import styles from './HeaderLogo.module.scss'

const HeaderLogo = ({ href, isJP }) => (
  <SmartLink to="/section" as={href}>
    <h1>
      {isJP ? (
        <div className={styles.JPLogo}>
          <img src="/image/JPNessyLogo.png" alt="jp logo" />
        </div>
      ) : (
        <Logo width={88} animated interactive />
      )}
    </h1>
  </SmartLink>
)

HeaderLogo.propTypes = {
  href: string.isRequired,
  isJP: bool
}

export default HeaderLogo
