import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { arrayOf, shape, string, func, bool, number } from 'prop-types'
import { useTranslation } from 'react-i18next'

import useEscapeKey from '@/hook/useEscapeKey'
import useOutsideClick from '@/hook/useOutsideClick'

import styles from './Header.module.scss'

import HeaderLogo from './component/HeaderLogo'
import Navigation from '../Navigation'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Icon from '@/component/Primitive/Icon'
import IconButton from '@/component/Primitive/IconButton'
import ScrollNav from '@/component/Primitive/ScrollNav'
import SearchOverlay from '@/component/Primitive/SearchOverlay'
import Spacer from '@/component/Primitive/Spacer'

const Header = ({
  currentPath,
  navItems,
  subNavItems,
  __url,
  basketCount,
  isJP
}) => {
  const { t } = useTranslation()
  const [mobileNavVisible, setMobileNavVisibility] = useState(false)
  const [searchVisible, setSearchVisibility] = useState(false)

  const handleMobileNavVisibility = () => {
    setMobileNavVisibility(!mobileNavVisible)
  }
  const handleSearchVisibility = (e) => {
    e.preventDefault()
    setSearchVisibility(!searchVisible)
  }

  const searchOverlayRef = useRef()
  useEscapeKey(searchVisible && handleSearchVisibility)
  useOutsideClick(searchOverlayRef, searchVisible && handleSearchVisibility)

  return (
    <>
      <header
        role="banner"
        className={classNames(styles.Header, isJP && styles.JP)}
      >
        <div className={styles.HeaderInner}>
          <div className={styles.HeaderPrimary}>
            <div className={styles.HeaderMobileButton}>
              <IconButton
                className={styles.HeaderToggle}
                icon={mobileNavVisible ? 'close' : 'menu'}
                onClick={handleMobileNavVisibility}
                a11yText={
                  mobileNavVisible
                    ? t('HeaderHideNavigation')
                    : t('HeaderRevealNavigation')
                }
                small
                increaseHitArea
              />
            </div>

            <div className={styles.HeaderLogo}>
              <HeaderLogo href={__url('/')} isJP={isJP} />
            </div>
            <div className={styles.HeaderMobileRightContainer}>
              <a
                className={styles.CartMobile}
                style={{ color: '#3bae70', textDecoration: 'none' }}
                href={t('HeaderBasketButtonLink')}
                title={t('HeaderBasketButton')}
              >
                <Icon type="basket" a11yText="" />{' '}
                {basketCount && <span>{basketCount}</span>}
              </a>
              <div className={styles.HeaderMobileButton}>
                <IconButton
                  className={styles.HeaderSearch}
                  icon={searchVisible ? 'close' : 'search'}
                  onClick={handleSearchVisibility}
                  a11yText={
                    searchVisible
                      ? t('HeaderHideSearch')
                      : t('HeaderRevealSearch')
                  }
                  small
                  increaseHitArea
                />
              </div>
            </div>
          </div>

          <div
            className={classNames(
              styles.HeaderSecondary,
              !mobileNavVisible && styles.collapsed
            )}
          >
            <div className={styles.HeaderPrimaryNavigation}>
              <Navigation primary id="navigation" label="Primary navigation">
                {navItems?.map((item, i) => (
                  <Navigation.Item
                    key={`NavItem:${i}`}
                    primary
                    to="/section"
                    as={item.link}
                    active={currentPath.startsWith(item.link)}
                    onClick={handleMobileNavVisibility}
                  >
                    <span
                      className={item.isShopLink ? styles.NavItemWithIcon : ''}
                    >
                      {item.name}
                    </span>
                    {item.isShopLink && (
                      <Icon a11yText="Shop" type="shopping-cart" />
                    )}
                  </Navigation.Item>
                ))}
              </Navigation>
            </div>

            <div className={styles.HeaderSecondaryNavigation}>
              <Navigation id="user-navigation" label="Secondary navigation">
                <Navigation.Item
                  href="/search"
                  onClick={handleSearchVisibility}
                  title={t('HeaderSearchButtonTitle')}
                  desktopOnly
                >
                  <Icon type="search" a11yText="" width={28} />
                </Navigation.Item>
                <Navigation.Item
                  saveSpace
                  href={__url(t('HeaderHelpButtonLink'))}
                  title={t('HeaderHelpButtonTitle')}
                >
                  <Icon type="help" a11yText="" />
                  <span>{t('HeaderHelpButtonTitle')}</span>
                </Navigation.Item>
                <Navigation.Item
                  saveSpace
                  href={t('HeaderAccountButtonLink')}
                  title={t('HeaderAccountButton')}
                >
                  <Icon type="person" a11yText="" />
                  <span>{t('HeaderAccountButton')}</span>
                </Navigation.Item>
                <Navigation.Item
                  active
                  href={t('HeaderBasketButtonLink')}
                  title={t('HeaderBasketButton')}
                >
                  <Spacer
                    style={{
                      display: 'inline-block',
                      transform: 'translateY(-3px)'
                    }}
                  >
                    <Icon type="basket" a11yText="" />
                  </Spacer>
                  {basketCount && <span>{basketCount}</span>}
                </Navigation.Item>
              </Navigation>
            </div>

            <div className={styles.HeaderTertiaryNavigation}>
              <ButtonStandard small href="https://learn.nessy.com/">
                {t('HeaderNessyPortalButton')}
              </ButtonStandard>
            </div>
          </div>
        </div>
        {subNavItems.length > 0 && (
          <ScrollNav>
            {subNavItems.map((item, i) => (
              <ScrollNav.Item
                key={`ScrollNavItem${i}`}
                to="/section"
                as={__url(item.link)}
                active={currentPath.startsWith(item.link)}
              >
                {item.name}
              </ScrollNav.Item>
            ))}
          </ScrollNav>
        )}
      </header>

      {searchVisible && (
        <div className={styles.HeaderSearchOverlay}>
          <SearchOverlay
            setRef={searchOverlayRef}
            onSubmit={handleSearchVisibility}
            __url={__url}
            // autocompletions={['Three', 'Four']}
          />
        </div>
      )}
    </>
  )
}

Header.defaultProps = {
  navItems: [],
  subNavItems: []
}

Header.propTypes = {
  currentPath: string,
  navItems: arrayOf(
    shape({
      name: string,
      link: string,
      isShopLink: bool
    })
  ),
  subNavItems: arrayOf(
    shape({
      name: string,
      link: string
    })
  ),
  __url: func.isRequired,
  basketCount: number,
  isJP: bool
}

export default Header
