import React from 'react'
import { arrayOf, shape, string, object, bool } from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './Footer.module.scss'

import BackgroundImage from '@/component/Primitive/BackgroundImage'
import IconButton from '@/component/Primitive/IconButton'
import Inline from '@/component/Primitive/Inline'
import Logo from '@/component/Primitive/Logo'
import SmartLink from '@/component/Primitive/SmartLink'

const Footer = ({
  currentPath,
  navItems,
  bgImages,
  instanceSlug,
  privacySection,
  isJP
}) => {
  const { t } = useTranslation()
  const isHomepage = currentPath?.split('/').length <= 2
  const privacyPolicyUrl =
    instanceSlug && privacySection?.fullUrlPath
      ? `/${instanceSlug}${privacySection.fullUrlPath}`
      : null
  return (
    <footer
      className={classNames(
        styles.Footer,
        isHomepage && styles.FooterHomepage,
        isJP && styles.JP
      )}
    >
      <div className={styles.FooterContainer}>
        {bgImages && (
          <div className={styles.FooterBackground}>
            <BackgroundImage
              alt=""
              fillContainer
              position="50% 0%"
              srcSet={[
                {
                  width: 0,
                  src: bgImages.mobile
                },
                {
                  width: 400,
                  src: bgImages.tablet
                },
                {
                  width: 800,
                  src: bgImages.desktop
                }
              ]}
            />
          </div>
        )}
        <div className={styles.FooterInner}>
          <div className={styles.FooterLogo}>
            <SmartLink to="/section" as="/">
              {isJP ? (
                <div className={styles.JPLogo}>
                  <img src="/image/JPNessyLogo.png" alt="jp logo" />
                </div>
              ) : (
                <Logo width={88} interactive />
              )}
            </SmartLink>
            <span className={styles.AdditionalLogo}>
              <img src="/image/CW75.png" alt="Commonwealth 75 publication" />
            </span>
            <span>
              <img
                className={styles.ILTLogo}
                src="/image/ilt-logo.png"
                alt="ILT Education"
              />
            </span>
          </div>

          <div className={styles.FooterPrimary}>
            <ul className={styles.FooterList}>
              {navItems?.map((item, i) => (
                <li key={`NavItem:${i}`}>
                  <SmartLink
                    className={classNames(
                      currentPath.startsWith(item.link) && styles.active
                    )}
                    to="/section"
                    as={item.link}
                  >
                    {item.name}
                  </SmartLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.FooterSecondary}>
            <div className={styles.FooterSocial}>
              <span className={styles.FooterSocialTitle}>
                {t('SocialFollowUs')}
              </span>
              <Inline shrink>
                <IconButton
                  icon="facebook"
                  href="http://www.facebook.com/nessy.learning"
                  a11yText={t('SocialVisitFacebook')}
                  solid
                  rounded
                  light
                  hoverGrow
                />
                <IconButton
                  icon="twitter"
                  href="https://twitter.com/nessylearning"
                  a11yText={t('SocialVisitTwitter')}
                  solid
                  rounded
                  light
                  hoverGrow
                />
                <IconButton
                  icon="youtube"
                  href="https://www.youtube.com/user/greenandfriendly"
                  a11yText={t('SocialVisitYouTube')}
                  solid
                  rounded
                  light
                  hoverGrow
                />
                <IconButton
                  icon="instagram"
                  href="https://www.instagram.com/nessylearning"
                  a11yText={t('SocialVisitInstagram')}
                  solid
                  rounded
                  light
                  hoverGrow
                />
              </Inline>
            </div>
          </div>
        </div>

        <small className={styles.FooterSmall}>
          {privacyPolicyUrl && (
            <a href={privacyPolicyUrl}>Privacy Policy T&Cs</a>
          )}
          <Inline>
            <span>© {new Date().getFullYear()} Nessy Learning</span>
            <a href="https://www.clock.co.uk">Website by Clock</a>
          </Inline>
        </small>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  bgImages: shape({
    mobile: string,
    tablet: string,
    desktop: string
  }),
  currentPath: string,
  navItems: arrayOf(
    shape({
      name: string,
      link: string
    })
  ),
  instanceSlug: string,
  privacySection: object,
  isJP: bool
}

export default Footer
